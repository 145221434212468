<template>
  <div class="overflow-hidden bg-gray-50 py-16 lg:py-24">
    <div class="relative mx-auto max-w-xl px-4 sm:px-6 lg:max-w-6xl lg:px-8">
      <svg
        class="absolute left-full hidden -translate-x-1/2 -translate-y-1/4 transform lg:block"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
      </svg>

      <div class="relative">
        <h2
          class="text-center text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl"
        >
          Sign up for a partner account
        </h2>
        <p class="mx-auto mt-4 max-w-3xl text-center text-xl text-gray-500">
          Our website was designed from the ground up—with you in mind.
          <br />
          See how we can help you keep track of your hardware needs.
        </p>
      </div>

      <div class="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:items-center lg:gap-8">
        <div class="relative">
          <h3 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
            Keep track of products
          </h3>
          <p class="mt-3 text-lg text-gray-500">
            With numerous jobs requiring almost the exact same screws, we make keeping products
            organized super tight.
          </p>

          <dl class="mt-10 space-y-10">
            <div class="relative">
              <dt>
                <div
                  class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-action text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
                    />
                  </svg>
                </div>
                <p class="ml-16 text-lg font-medium leading-6 text-gray-900">Labeled favorites</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">
                Save an item to your favorites and attach a label to it. Anywhere that product is on
                the site will have that label.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <div
                  class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-action text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M16.5 3.75V16.5L12 14.25 7.5 16.5V3.75m9 0H18A2.25 2.25 0 0120.25 6v12A2.25 2.25 0 0118 20.25H6A2.25 2.25 0 013.75 18V6A2.25 2.25 0 016 3.75h1.5m9 0h-9"
                    />
                  </svg>
                </div>
                <p class="ml-16 text-lg font-medium leading-6 text-gray-900">
                  User friendly order history
                </p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">
                Name your orders based on the job name or on the purchase order. In order history,
                just search by either. Oh and you can search your orders by part number.
              </dd>
            </div>

            <div class="relative">
              <dt>
                <div
                  class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-action text-white"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                    />
                  </svg>
                </div>
                <p class="ml-16 text-lg font-medium leading-6 text-gray-900">Plan ahead</p>
              </dt>
              <dd class="mt-2 ml-16 text-base text-gray-500">
                We have made bidding on a job much easier. Add all the products for a job into your
                cart and save the cart for later. Then when you're ready to purchase, just check
                out.
              </dd>
            </div>
          </dl>
        </div>

        <div class="relative -mx-4 mt-10 lg:mt-0" aria-hidden="true">
          <svg
            class="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden"
            width="784"
            height="404"
            fill="none"
            viewBox="0 0 784 404"
          >
            <defs>
              <pattern
                id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                x="0"
                y="0"
                width="20"
                height="20"
                patternUnits="userSpaceOnUse"
              >
                <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
              </pattern>
            </defs>
            <rect width="784" height="404" fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
          </svg>
          <img class="relative mx-auto" width="490" :src="imgPathOne" alt="" />
        </div>
      </div>

      <svg
        class="absolute right-full hidden translate-x-1/2 translate-y-12 transform lg:block -z-10"
        width="404"
        height="784"
        fill="none"
        viewBox="0 0 404 784"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"
            x="0"
            y="0"
            width="20"
            height="20"
            patternUnits="userSpaceOnUse"
          >
            <rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
          </pattern>
        </defs>
        <rect width="404" height="784" fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />
      </svg>

      <div class="relative mt-12 sm:mt-16 lg:mt-24">
        <div class="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:items-center lg:gap-8">
          <div class="lg:col-start-2">
            <h3 class="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Special Pricing
            </h3>
            <p class="mt-3 text-lg text-gray-500">
              As a local company, we want to grow with you. To grow with you, we offer
              individualized pricing on every product.
            </p>

            <dl class="mt-10 space-y-10">
              <div class="relative">
                <dt>
                  <div
                    class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-action text-white"
                  >
                    <!-- Heroicon name: outline/chat-bubble-bottom-center-text -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z"
                      />
                    </svg>
                  </div>
                  <p class="ml-16 text-lg font-medium leading-6 text-gray-900">
                    Negotiate your price
                  </p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  When you make a partner account, you'll get a call from one of our partnership
                  managers. Our manager will determine what products you need and see what we can
                  do.
                </dd>
              </div>

              <div class="relative">
                <dt>
                  <div
                    class="absolute flex h-12 w-12 items-center justify-center rounded-md bg-action text-white"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                      />
                    </svg>
                  </div>
                  <p class="ml-16 text-lg font-medium leading-6 text-gray-900">See your price</p>
                </dt>
                <dd class="mt-2 ml-16 text-base text-gray-500">
                  On every product, on every page, you see your price.
                </dd>
              </div>
            </dl>
          </div>

          <div class="relative -mx-4 mt-10 lg:col-start-1 lg:mt-0">
            <svg
              class="absolute left-1/2 -translate-x-1/2 translate-y-16 transform lg:hidden -z-10"
              width="784"
              height="404"
              fill="none"
              viewBox="0 0 784 404"
              aria-hidden="true"
            >
              <defs>
                <pattern
                  id="e80155a9-dfde-425a-b5ea-1f6fadd20131"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    class="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect width="784" height="404" fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />
            </svg>
            <img
              class="relative mx-auto rounded-lg"
              width="490"
              src="/images/static/special_pricing.PNG"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-10 z-10">
      <div class="mx-auto max-w-7xl py-12 px-4 text-center sm:px-6 lg:py-16 lg:px-8">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          <span class="block">Ready to join?</span>
          <span class="block">Create a partner account</span>
        </h2>
        <div class="mt-8 flex justify-center">
          <div class="inline-flex rounded-md shadow">
            <router-link
              to="/commercial"
              tag="button"
              class="inline-flex items-center justify-center rounded-md border border-transparent bg-action px-5 py-3 text-base font-medium text-white hover:bg-primary"
            >
              Get started
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      imgPathOne: '/images/static/are-you-a-pro.webp',
      imgPathTwo: '/images/static/do-it-yourself.webp',
    };
  },
  computed: {
    layouts() {
      return this.$store.getters.getLayouts('Commercial Info');
    },
  },
  metaInfo() {
    return {
      title: 'Online Hardware Supply Store for Contractors | Page Custom Supply',
      meta: [
        {
          name: 'description',
          content:
            'Page Custom Supply Pro Account offers competitive benefits and advantages to Contractors. Click here today to learn more about one of the top Online Hardware Supply Stores!',
        },
        { name: 'keywords', content: 'online hardware supply' },
      ],
    };
  },
  watch: {},
  mounted() {
    setTimeout(() => {
      if (!window.$webpSupport) {
        this.imgPathOne = '/images/static/are-you-a-pro.jpg';
        this.imgPathTwo = '/images/static/do-it-yourself.jpg';
      }
    }, 1000);
  },
  methods: {},
  components: {},
};
</script>
<style scoped></style>
